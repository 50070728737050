<template>
  <div id="cms-entry-show">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Entry - Show</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-10" style="color: white; text-align: right">
            <b-row>
              <b-col class="col-sm-5" style="margin-left: 6em">
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Type:"
                        label-for="type"
                >
                  <b-form-input id="type" type="text" v-model="entryType['name']" style="margin-left: 3em" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Date:"
                        label-for="date"
                >
                  <b-form-input id="date" type="date" v-model="entry.date" style="margin-left: 3em" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Amount:"
                        label-for="amount"
                >
                  <b-form-input id="amount" type="number" v-model="entry.amount" style="margin-left: 3em" disabled></b-form-input>
                </b-form-group>
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Remark:"
                        label-for="remark"
                >
                  <b-form-input id="remark" type="text" v-model="entry.remark" style="margin-left: 3em" disabled></b-form-input>
                </b-form-group>
                <router-link class="link-color" :to="{ name: 'CmsEntries' }" @click.prevent>
                  <b-button type="reset" variant="success">Back</b-button>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsEntryUpdate' }" @click.prevent>
                  <b-button type="reset" variant="warning">Edit</b-button>
                </router-link>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'

export default {
  data() {
    return {
      loading: true,
      entry: [],
      entryType: [],
    }
  },
  components: {
    SideMenu
  },
  beforeCreate() {
    this.axios
        .get(`https://batavia-backend.herokuapp.com/api/entry/${this.$route.params.id}`)
        .then(response => (
            this.entry = response.data
        ))
        .finally( () => {
          this.axios
              .get('https://batavia-backend.herokuapp.com/api/entry-type/' + this.entry.type_id)
              .then(response => (
                  this.entryType = response.data,
                  this.loading = false
              ))
        })
  }
}
</script>